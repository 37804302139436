<template>
  <b-overlay :show="loading">
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <!-- <b-img src="@/assets/images/logo/logo.jpg" width="40%" /> -->
            <h1 class="brand-text text-primary ml-1">USER ADMIN ELP</h1>
          </b-link>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label-for="email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukkan email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-link
                  class="float-right"
                  :to="{ name: 'admin-forgot-password' }"
                >
                  <small>Lupa Kata Sandi?</small>
                </b-link>
                <!-- <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox> -->
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
              <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox>
            </b-form-group> -->

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BOverlay,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // BSV
    BOverlay,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
      loading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const payload = {
            email: this.userEmail,
            password: this.password,
          };
          this.$store
            .dispatch("auth-admin/login", payload)
            .then((res) => {
              this.loading = false;
              if (res.data.data.level.nama_level == "Siswa") {
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                this.$router.replace("/login").then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Gagal Login`,
                      icon: "XIcon",
                      variant: "danger",
                      text: `Anda bukan admin ${res.data.data.name}!!`,
                    },
                  });
                });
              }
              // else if (res.data.data.level.nama_level == "Super Admin") {
              //   localStorage.removeItem("token");
              //   localStorage.removeItem("userData");
              //   this.$router.replace("/login").then(() => {
              //     this.$toast({
              //       component: ToastificationContent,
              //       position: "top-right",
              //       props: {
              //         title: `Gagal Login`,
              //         icon: "XIcon",
              //         variant: "danger",
              //         text: `Anda bukan admin ${res.data.data.name}!!`,
              //       },
              //     });
              //   });
              // }
              else {
                this.$router
                  .replace(getHomeRouteForLoggedInUser("User Admin"))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Selamat Datang`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `Kamu berhasil login ${res.data.data.name}`,
                      },
                    });
                  });
              }
            })
            .catch((error) => {
              this.loading = false;
              console.error("error", error);
              this.$refs.loginForm.setErrors(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: error.response.data.message,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
